import * as React from "react";
import { Component } from "react";
import GrayscaleHistory from "../../images/featured-articles-section_hero-desktop.jpg";
import GrayscaleHistoryMobile from "../../images/featured-articles-section_hero-mobile.jpg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


class HistoryOfHIV extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
  }
  handleClose = (show) => {
    this.setState({ show: false });
  };
  handleShow = (show) => {
    this.setState({ show: true });
  };
  render() {
    return (
      <>
        <Container className="section mb-32">
          <Row>
            <Col xs={10} className="m-auto">
              <h2 className="title">FEATURED ARTICLES</h2>
              <Container className="history-of-hiv">
                <Row>
                  <Col
                    xs={12}
                    lg={4}
                    className="px-0 d-flex align-items-center order-1 order-lg-0"
                  >
                    <div className="content bg-white gtm-track-video-overlay">
                      <div className="cont-text">
                        <p className="mb-4">
                          Explore articles about current developments in the HIV community, ways people and organizations are addressing barriers to care, and the leading efforts to end the HIV epidemic for everyone, everywhere.
                        </p>
                        <span className="watch text-right">
                          <a
                            href="/deeper-dive/content-index"
                            className="no-interstitial button large secondary arrow"
                          >
                            Discover more
                          </a>
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={8} className="px-0 order-0 order-lg-1">
                    <img
                      src={GrayscaleHistory}
                      className="d-none d-md-block image-underlay mb-0"
                      alt="Play the history of HIV"
                    />
                    <img
                      src={GrayscaleHistoryMobile}
                      className="d-block d-md-none image-underlay mb-2"
                      alt="Play the history of HIV"
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default HistoryOfHIV;
