import * as React from "react";
import { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import PlayBtn from "../../images/button-play.png";
import PauseBtn from "../../images/button-pause.png";

class HeroCarousel extends Component {
   constructor() {
      super();
      this.state = {
         show: false,
         paused: false, // Estado para controlar si el carousel está pausado
      };
   }

   handlePauseToggle = () => {
      this.setState((prevState) => ({
         paused: !prevState.paused,
      }));
   };

   render() {
      return (
         <Container className="px-0">
            <Row>
               <Col>
                  <Carousel
                     interval={this.state.paused ? null : 7000} // Si paused es true, no hay intervalo (pausado)
                     keyboard={true}
                  >
                     {/* Primer elemento del carousel */}
                     <Carousel.Item>
                        <img
                           className="d-none d-md-block w-100"
                           src={"/homepage-we-are-all-in-this-together-hero.jpg"}
                           alt="We are all in this together"
                        />
                        <img
                           className="d-block d-md-none w-100"
                           src={"/homepage-we-are-all-in-this-together-hero-mobile.jpg"}
                           alt="We are all in this together"
                        />
                        <Carousel.Caption className="all-together">
                           <Container>
                              <Row className="h-100">
                                 <Col
                                    xs={{ span: 6, offset: 9 }}
                                    md={{ span: 8, offset: 8 }}
                                    lg={{ span: 6, offset: 9 }}
                                    xl={{ span: 6, offset: 9 }}
                                    className="pl-3 pl-md-0 pl-xl-3"
                                 >
                                    <a className="no-interstitial nodecor">
                                       <div className="cont-slide pl-0">
                                          <h2 className="text-uppercase">
                                             We Are All in this together
                                          </h2>
                                          <p className="d-none d-lg-block">
                                             <span>
                                                <strong>As a community,</strong> we are working to combat stigma, educate about HIV, motivate people to get tested, get them connected to care, and encourage everyone to learn about HIV prevention and treatment options. It takes all of this, and all of us working together, to end the HIV epidemic for everyone, everywhere.
                                             </span>
                                             <a className="button medium dark align-self-end mt-lg-3" href="/about-us">
                                                Learn more
                                             </a>
                                          </p>
                                          <a className="button medium dark d-inline-flex d-lg-none" href="/about-us">
                                             Learn More
                                          </a>
                                       </div>
                                    </a>
                                 </Col>
                              </Row>
                           </Container>
                        </Carousel.Caption>
                     </Carousel.Item>

                     {/* Segundo elemento del carousel */}
                     <Carousel.Item>
                        <img
                           className="d-none d-md-block w-100"
                           src={"/homepage-social-media-hero.jpg"}
                           alt="Social Media Toolkits"
                        />
                        <img
                           className="d-block d-md-none w-100"
                           src={"/homepage-social-media-hero-mobile.jpg"}
                           alt="Social Media Toolkits"
                        />
                        <Carousel.Caption className="toolkit">
                           <Container>
                              <Row className="h-100">
                                 <Col
                                    xs={{ span: 7, offset: 7 }}
                                    md={{ span: 6, offset: 7 }}
                                    lg={{ span: 7, offset: 7 }}
                                    className="px-1 pl-xl-5"
                                 >
                                    <a className="no-interstitial nodecor">
                                       <div className="cont-slide pl-0">
                                          <h2 className="text-uppercase mb-3">
                                             SOCIAL MEDIA <span>TOOLKITS</span>
                                          </h2>
                                          <p className="d-none d-lg-block">
                                             Help call attention to HIV awareness days throughout the year by building your own posts for Instagram, Facebook, and X with our free social media toolkits. <span>Download and post our free social media graphics in recognition of HIV awareness days throughout the year.</span>
                                          </p>
                                          <a
                                             className="button medium dark download"
                                             href="/community-involvement/social-toolkit"
                                          >
                                             Download free graphics
                                          </a>
                                       </div>
                                    </a>
                                 </Col>
                              </Row>
                           </Container>
                        </Carousel.Caption>
                     </Carousel.Item>

                     {/* Tercer elemento del carousel */}
                     <Carousel.Item>
                        <img
                           className="d-none d-md-block w-100"
                           src={"/homepage-prep-matters-hero.png"}
                           alt="PrEP Matters: A Guide to Advancing Uptake"
                        />
                        <img
                           className="d-block d-md-none w-100"
                           src={"/homepage-prep-matters-hero-mobile.png"}
                           alt="PrEP Matters: A Guide to Advancing Uptake"
                        />
                        <Carousel.Caption className="prep-matters">
                           <Container>
                              <Row className="h-100">
                                 <Col
                                    xs={{ span: 7 }}
                                    sm={{ span: 8 }}
                                    md={{ span: 6, offset: 1 }}
                                    className="pl-2 px-md-0"
                                 >
                                    <a className="no-interstitial nodecor">
                                       <div className="cont-slide">
                                          <h2 className="font-heavy line-height-25-mob d-none d-lg-block">
                                             PrEP Matters: <span className="no-break">A Guide</span> to Advancing Uptake
                                          </h2>
                                          <h2 className="font-heavy line-height-25-mob d-lg-none">
                                             PrEP Matters: A Guide to Advancing Uptake for allied healthcare professionals
                                          </h2>
                                          <p className="d-none d-lg-block">
                                             Explore this downloadable resource for allied healthcare professionals on advancing <span className="no-break">pre-exposure</span> prophylaxis (PrEP) uptake at the moment of HIV and STI testing.
                                          </p>
                                          <a
                                             className="button medium dark open_isi"
                                             href="/downloadable-resource/prep-basics"
                                          >
                                             Learn More
                                          </a>
                                       </div>
                                    </a>
                                 </Col>
                              </Row>
                           </Container>
                        </Carousel.Caption>
                     </Carousel.Item>

                  </Carousel>

                  <span className="carousel-controls"
                        onClick={this.handlePauseToggle}
                     >
                        {this.state.paused ? <img className='' width={16} height={18} src={PlayBtn} alt='Play Button'/> : <img className='' width={16} height={18} src={PauseBtn} alt='Pause Button'/>}
                     </span>
               </Col>
            </Row>
         </Container>
      );
   }
}

export default HeroCarousel;
