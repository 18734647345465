import * as React from "react";
import { Component } from 'react';
import HeroCarousel from './hero-carousel.js'
import Featured from './featured.js'
import HIVLandscape from './hiv-landscape.js'
import HistoryOfHIV from './history-of-hiv.js'
import CommunityEvents from './community-events.js'

class Content extends Component {
  render() {
    return (
      <div className={`
        ${this.props.show ? 'd-block' : 'd-none'}
        home
      `}>
        <HeroCarousel />
        <Featured />
        <HIVLandscape />
        <HistoryOfHIV />
        <CommunityEvents />
      </div>
    )
  }
}

export default Content;