import * as React from 'react';
import CalendarImage from '../../images/community-events-section_hero-desktop.jpg';
import CalendarImageMobile from '../../images/community-events-section_hero-mobile.jpg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Parser from 'html-react-parser';

const CommunityEvents = () => {
    const events = [
        /**
        {
            title: 'Press Play on Knowing Your HIV Status:',
            titleState: 'Orlando, FL', // this is concatenated to the title, done separately to wrap the city with the arrow in a span
            date: '6/22/2024',
            description:
                'Gilead is proud to sponsor this National HIV Testing Day event. Free HIV testing will be offered by local community...'
        },
        {
            title: 'Press Play on Knowing Your HIV Status:',
            titleState: 'Fort Lauderdale, FL', // this is concatenated to the title, done separately to wrap the city with the arrow in a span
            date: '6/22/2024',
            description: 'Gilead is proud to sponsor this National HIV Testing Day event. Free HIV testing will be offered by local community...'
        },
        {
            title: 'Press Play on Knowing Your HIV Status:',
            titleState: 'Dallas, TX', // this is concatenated to the title, done separately to wrap the city with the arrow in a span
            date: '6/25/2024',
            description: "Gilead is proud to sponsor this National HIV Testing Day event. Free HIV testing will be offered by local community..."
        }
        */

        {
            title: 'World AIDS Day',
            titleState: '', // this is concatenated to the title, done separately to wrap the city with the arrow in a span
            date: '12/1/2024',
            description: "Join Gilead in recognizing World AIDS Day on December 1 each year. Download our free social media toolkit to acknowledge all those who have been lost to <nobr>AIDS-related</nobr> illnesses and all those impacted by HIV. "
        }
        
    ];
    return (
        <>
            <div className='section community-events'>
                <Row>
                    <div class="full-width-bg">
                        <Container>
                        <Col xs={11} md={10} className='mx-auto'>
                            <h2>OUR UPCOMING PROGRAMS & EVENTS</h2>
                        </Col>
                        </Container>
                    </div>
                    <Container>
                    <Col xs={12} md={10} className='mx-auto'>
                        <Container>
                            <Row>
                                <Col
                                    xs={12}
                                    md={{ span: 5, order: 2 }}
                                    className='px-0 justify-content-center d-flex mb-2 mb-md-0'
                                >
                                    <img
                                        className='community d-none d-md-block'
                                        src={CalendarImage}
                                        alt='Two women outside in the woods'
                                    />
                                    <img
                                        className='community d-block d-md-none w-100'
                                        src={CalendarImageMobile}
                                        alt='Two women outside in the woods'
                                    />
                                </Col>
                                <Col md={{ span: 7, order: 1 }}>
                                    <Container>
                                        <Row>
                                            {events.map((event, index) => (
                                                <Col sm={12} className='event' key={index}>
                                                    <a href='/community-involvement/events/'>
                                                        <span className='date'>{event.date}</span>
                                                        <span className='title'>
                                                            {Parser(event.title)}{' '}
                                                            <span className='no-break'>{Parser(event.titleState)} {' '}<i className='fa fa-chevron-right big'></i></span>
                                                        </span>
                                                        
                                                        <p className='description '>
                                                            {Parser(event.description)}
                                                        </p>
                                                    </a>
                                                </Col>
                                            ))}
                                            <Col sm={12} className="text-center text-md-left">
                                                <a
                                                    className='button large light'
                                                    href='/community-involvement/events'
                                                >
                                                    View full calendar
                                                </a>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    </Container>
                </Row>
            </div>
        </>
    );
};

export default CommunityEvents;
