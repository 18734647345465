import * as React from "react";
import HIVLandscapeIMG from "../../images/hiv-landscape-section_hero-desktop.jpg";
import HIVLandscapeIMGMobile from "../../images/hiv-landscape_section-hero-mobile.jpg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import HistoryOfHIV from "../../images/hiv-landscape-cards/history-of-hiv.svg";
//import HistoryOfHIVMobile from "../../images/hiv-landscape-cards/featured-articles-section_hero-mobile.jpg";

import StateOfEpidemic from "../../images/hiv-landscape-cards/state-of-epidemic.svg";
//import StateOfEpidemicMobile from "../../images/hiv-landscape-cards/featured-articles-section_hero-mobile.jpg";

import PlansForEHE from "../../images/hiv-landscape-cards/plans-for-ehe.svg";
//import PlansForEHEMobile from "../../images/hiv-landscape-cards/featured-articles-section_hero-mobile.jpg";

import HIVCareContinuum from "../../images/hiv-landscape-cards/hiv-care-continuum.svg";
//import HIVCareContinuumMobile from "../../images/hiv-landscape-cards/featured-articles-section_hero-mobile.jpg";

import HIVTesting from "../../images/hiv-landscape-cards/hiv-testing.svg";
//import HIVTestingMobile from "../../images/hiv-landscape-cards/featured-articles-section_hero-mobile.jpg";

import HIVRapidStart from "../../images/hiv-landscape-cards/hiv-rapid-start.svg";
//import HIVRapidStartMobile from "../../images/hiv-landscape-cards/featured-articles-section_hero-mobile.jpg";

const HIVLandscape = () => {
   return (
      <div className="hiv-landscape-cards mb-32 mx-auto">
         <ul>
            <li className="card-item">
               <a href="/landscape/history-of-hiv">
               <div className="image-container">
                  <img src={HistoryOfHIV} alt="Card 1" />
                  <div className="overlay">
                     <div className="intro-text">HISTORY of <br className="d-none d-lg-block" />the hiv epidemic</div>
                     <div className="full-text">HISTORY<br /> of the hiv<br /> epidemic</div>
                  </div>
               </div>
               </a>
            </li>

            <li className="card-item">
               <a href="/landscape/state-of-epidemic">
               <div className="image-container">
                  <img src={StateOfEpidemic} alt="Card 2" />
                  <div className="overlay">
                     <div className="intro-text">State of the HIV <br className="d-none d-lg-block" />Epidemic</div>
                     <div className="full-text">State of<br /> the HIV<br /> Epidemic</div>
                  </div>
               </div>
               </a>
            </li>

            <li className="card-item">
               <a href="/landscape/ehe">
               <div className="image-container">
                  <img src={PlansForEHE} alt="Card 3" />
                  <div className="overlay">
                     <div className="intro-text">Plans for ending <br/>the HIV Epidemic</div>
                     <div className="full-text">Plans for<br /> ending<br /> the HIV<br /> Epidemic</div>
                  </div>
               </div>
               </a>
            </li>

            <li className="card-item">
               <a href="/landscape/hiv-care-continuum">
               <div className="image-container">
                  <img src={HIVCareContinuum} alt="Card 4" />
                  <div className="overlay">
                     <div className="intro-text">HIV Care <br className="d-none d-lg-block" />Continuum</div>
                     <div className="full-text">HIV Care<br /> Continuum</div>
                  </div>
               </div>
               </a>
            </li>

            <li className="card-item">
               <a href="/landscape/hiv-testing">
               <div className="image-container">
                  <img src={HIVTesting} alt="Card 5" />
                  <div className="overlay">
                     <div className="intro-text">Hiv <br className="d-none d-lg-block" />Testing</div>
                     <div className="full-text">Hiv Testing</div>
                  </div>
               </div>
               </a>
            </li>

            <li className="card-item">
               <a href="/landscape/hiv-rapid-start">
               <div className="image-container">
                  <img src={HIVRapidStart} alt="Card 6" />
                  <div className="overlay">
                     <div className="intro-text">Rapid start <br className="d-none d-lg-block" />Initiation</div>
                     <div className="full-text">Rapid start<br /> Initiation</div>
                  </div>
               </div>
               </a>
            </li>
         </ul>
      </div>
   );
};

export default HIVLandscape;
